<template>
  <div>
    <div class="">
      <div v-for="plan in customerPlans" :key="plan.id">
        <div>
          <plan-selection-card :plan="plan" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref, watchEffect, // watchEffect, // computed, watch, computed, reactive, onMounted,
} from '@vue/composition-api'
import chargeScheduleEnum from '@/enums/membershipChargeSchedule.enum'
import { useCustomerRegistration } from '../useCustomerRegistration'

export default {
  components: {
    'plan-selection-card': () => import('./PlanSelectionCard.vue'),
  },
  setup() {
    const isMonthly = ref(true)
    const planSchedule = ref(chargeScheduleEnum.MONTHLY.value)
    const planId = ref(null)
    const { customerPlans, setPlanId, setChargeSchedule } = useCustomerRegistration()

    function setPlan(plan) {
      planId.value = plan.id
      setPlanId(planId.value)
    }

    function toggleSchedule() {
      isMonthly.value = !isMonthly.value
      planSchedule.value = isMonthly.value ? chargeScheduleEnum.MONTHLY.value : chargeScheduleEnum.YEARLY.value
      setChargeSchedule(planSchedule.value)
    }

    watchEffect(() => {
      if (customerPlans.value.length === 1) {
        planId.value = customerPlans.value[0].id
        setPlanId(planId.value)
        setChargeSchedule(planSchedule.value)
      }
    })

    return {
      customerPlans,
      isMonthly,
      setPlan,
      planId,
      toggleSchedule,
      planSchedule,
    }
  },
}
</script>
